import React from 'react'
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom'
import App from '../App'
import { Main } from './Main'
import "../App.css";
import { Detail } from './Detail';

export const Router = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Main></Main>}>
                </Route>
                <Route path="/beacon/:id" element={<Detail></Detail>}>
                </Route>
            </Routes>
        </BrowserRouter>
    )
}