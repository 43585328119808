import { Card, Statistic } from "antd";
import React from "react";
import {
  GlobalOutlined,
  CloudServerOutlined,
  PushpinOutlined,
} from "@ant-design/icons";

export const StatsFooter = ({
  totalMessage,
  totalHotSpots,
  totalBeacons,
}: {
  totalMessage: number;
  totalHotSpots: number;
  totalBeacons: number;
}) => {
  return (
    <>
      <Card
        bordered={false}
        bodyStyle={{ paddingBottom: 1, paddingTop: 1 }}
        style={{ background: "#001529", color: "#fff" }}
      >
        <Statistic
          title={
            <span style={{ color: "#9cada7" }}>Posiciones últimas 24h</span>
          }
          value={totalMessage}
          valueStyle={{ color: "#fff", fontSize: 22 }}
          prefix={<GlobalOutlined style={{ fontSize: 17, marginRight: 3 }} />}
        />
      </Card>
      <Card
        bodyStyle={{ paddingBottom: 1, paddingTop: 1 }}
        bordered={false}
        style={{ background: "#001529", color: "#fff" }}
      >
        <Statistic
          title={<span style={{ color: "#9cada7" }}>Hotspots desplegados</span>}
          value={totalHotSpots}
          valueStyle={{ color: "#fff", fontSize: 22 }}
          prefix={
            <CloudServerOutlined style={{ fontSize: 17, marginRight: 3 }} />
          }
        />
      </Card>
      <Card
        bodyStyle={{ paddingBottom: 10, paddingTop: 1 }}
        bordered={false}
        style={{ background: "#001529", color: "#fff" }}
      >
        <Statistic
          title={<span style={{ color: "#9cada7" }}>Beacons desplegados</span>}
          value={totalBeacons}
          valueStyle={{ color: "#fff", fontSize: 22 }}
          prefix={<PushpinOutlined style={{ fontSize: 17, marginRight: 3 }} />}
        />
      </Card>
    </>
  );
};

export const StatsFooterLoading = () => {
  return (
    <>
      <Card
        bordered={false}
        bodyStyle={{ paddingBottom: 0, paddingTop: 0 }}
        style={{ background: "#001529", color: "#fff" }}
        loading
      ></Card>
      <Card
        bordered={false}
        bodyStyle={{ paddingBottom: 15, paddingTop: 0 }}
        style={{ background: "#001529", color: "#fff" }}
        loading
      ></Card>
    </>
  );
};
