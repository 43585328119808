import React from "react";
import logo from "../logo.png";

export const SideBarLogo = () => {
  return (
    <div
      className="logo"
      style={{
        padding: "40px 0px 20px 0px",
        display: "flex",
        alignContent: "center",
        verticalAlign: "center",
        justifyContent: "center",
      }}
    >
      <img src={logo} alt="" width={"30%"} />
    </div>
  );
};
