import React from "react";
import GoogleMapReact from "google-map-react";

// @ts-ignore
const { format, register } = require("timeago.js");
register(
  "es_ES",
  (number: any, index: any, total_sec: any) =>
    [
      ["justo ahora", "ahora mismo"],
      ["hace %s segundos", "en %s segundos"],
      ["hace 1 minuto", "en 1 minuto"],
      ["hace %s minutos", "en %s minutos"],
      ["hace 1 hora", "en 1 hora"],
      ["hace %s horas", "in %s horas"],
      ["hace 1 dia", "en 1 dia"],
      ["hace %s dias", "en %s dias"],
      ["hace 1 semana", "en 1 semana"],
      ["hace %s semanas", "en %s semanas"],
      ["1 mes", "en 1 mes"],
      ["hace %s meses", "en %s meses"],
      ["hace 1 año", "en 1 año"],
      ["hace %s años", "en %s años"],
    ][index]
);
const timeago = (timestamp: any) => format(timestamp, "es_ES");

const AnyReactComponent = (props: any) => (
  <div {...props}>
    <img
      src={
        !props.status
          ? "http://maps.google.com/mapfiles/ms/micons/blue-dot.png"
          : "http://maps.google.com/mapfiles/ms/micons/red-dot.png"
      }
      alt="marker"
    />
  </div>
);

const LAT = 20.66458074400242;
const LNG = -103.35318124243272;

export const Map = ({
  bounds,
  lastPositions,
  line,
}: {
  bounds: any;
  lastPositions: any;
  line: boolean;
}) => {
  return (
    <div style={{ height: "100vh", width: "100%" }}>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: "AIzaSyBjlr0VdDZI9SYYHOmAfCijGKauidyz0gA",
        }}
        defaultCenter={{
          lat: LAT,
          lng: LNG,
        }}
        defaultZoom={15}
        onGoogleApiLoaded={(maps: any) => {
          lastPositions = lastPositions.filter((f: any) => {
            return !(f.latitude == 0 || f.longitude == 0)
          })
          const linee = lastPositions.map((e: any) => {
            return {
              lat: e.latitude,
              lng: e.longitude,
            };
          });
          if (line === true) {
            maps.map.data.add({
              geometry: new maps.maps.Data.LineString(linee),
            });
          }

          maps.map.fitBounds({
            north: bounds.bigLat,
            east: bounds.bigLng,
            south: bounds.smallLat,
            west: bounds.smallLng,
          });

          lastPositions.map((e: any) => {
            var myLatlng = new maps.maps.LatLng(e.latitude, e.longitude);

            var marker = new maps.maps.Marker({
              position: myLatlng,
              title: "Hello World!",
              icon: {
                url: !e.status ? "http://maps.google.com/mapfiles/ms/icons/blue-dot.png" : "http://maps.google.com/mapfiles/ms/icons/red-dot.png",
              },
            });
            marker.setMap(maps.map);

            const contentString = `
<div id="content">
	<div id="siteNotice">
	</div>
		<h1 id="firstHeading" class="firstHeading">#${e.beaconId} ${e.name}</h1>
	<div id="bodyContent">
   <p>
		<b>UUID</b> ${e.uuid}
    <br></br>
		<b>Último visto</b> ${timeago(new Date(e.dateTime * 1000))}
    <br></br>
		<b>Lat</b> ${e.latitude}
    <br></br>
		<b>Lng</b> ${e.longitude}
    <br></br>
		<b>Status</b> ${!e.status ? "Normal" : "Robado"}
   </p>

	</div>
</div>
	      `;

            const infowindow = new maps.maps.InfoWindow({
              content: contentString,
            });

            marker.addListener("click", () => {
              infowindow.open({
                anchor: marker,
                map: maps.map,
                shouldFocus: false,
              });
            });
          });
        }}
      >
        {/* {lastPositions.map((e: any) => {
          return (
            <AnyReactComponent
              lat={e.latitude}
              lng={e.longitude}
              status={e.status}
              text="My Marker"
            />
          );
        })} */}
      </GoogleMapReact>
    </div>
  );
};
