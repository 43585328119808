import { Layout } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Map } from './Map';
import { SideBar } from './SideBar';

export const Main = () => {
    const [loaded, setLoaded] = useState(false);
    const [data, setData] = useState<any>({})

    const fetchData = async () => {
        setLoaded(false)
        const res = await fetch("https://api.stealth.city/api/lastPosition");
        let json = await res.json();

        await new Promise((resolve) => setTimeout(resolve, 200));

        let bigLat = -10000000;
        let smallLat = 10000000;
        let bigLng = -10000000;
        let smallLng = 10000000;

        json.lastPositions.forEach((e: any) => {
            if (e.latitude === 0 || e.longitude === 0) return;

            if (e.latitude < smallLat) smallLat = e.latitude;
            if (e.latitude > bigLat) bigLat = e.latitude;

            if (e.longitude < smallLng) smallLng = e.longitude;
            if (e.longitude > bigLng) bigLng = e.longitude;
        });

        json.lastPositions = json.lastPositions.sort((a: any, b: any) => a.beaconId - b.beaconId)
        json.mapBounds = {
            bigLat: bigLat,
            bigLng: bigLng,
            smallLat: smallLat,
            smallLng: smallLng,
        }

        setData(json)
        setLoaded(true)
    }
    useEffect(() => {
        fetchData()
    }, [])

    return (
        <Layout hasSider>
            <SideBar data={data} loaded={loaded} onUpdate={() => {
                fetchData()
            }}
            ></SideBar>
            <Layout
                className="site-layout"
                style={{
                    marginLeft: 300,
                    height: "100vh",
                }}
            >
                <Content style={{ overflow: "initial" }}>
                    {loaded && (
                        <Map
                            bounds={data.mapBounds}
                            lastPositions={data.lastPositions}
                            line={false}
                        ></Map>
                    )}
                </Content>
            </Layout>
        </Layout>
    )
}