import { Card, Menu } from "antd";
import SubMenu from "antd/lib/menu/SubMenu";
import React from "react";
import { PushpinOutlined } from "@ant-design/icons";
import { Link, useNavigate } from 'react-router-dom'

// @ts-ignore
const { format, register } = require("timeago.js");
register(
  "es_ES",
  (number: any, index: any, total_sec: any) =>
    [
      ["justo ahora", "ahora mismo"],
      ["hace %s segundos", "en %s segundos"],
      ["hace 1 minuto", "en 1 minuto"],
      ["hace %s minutos", "en %s minutos"],
      ["hace 1 hora", "en 1 hora"],
      ["hace %s horas", "in %s horas"],
      ["hace 1 dia", "en 1 dia"],
      ["hace %s dias", "en %s dias"],
      ["hace 1 semana", "en 1 semana"],
      ["hace %s semanas", "en %s semanas"],
      ["1 mes", "en 1 mes"],
      ["hace %s meses", "en %s meses"],
      ["hace 1 año", "en 1 año"],
      ["hace %s años", "en %s años"],
    ][index]
);
const timeago = (timestamp: any) => format(timestamp, "es_ES");

export const BeaconsMenu = ({
  lastPositions,
  onBeaconClick,
  selectedBeacon,
}: {
  lastPositions: any;
  onBeaconClick: any;
  selectedBeacon: string;
}) => {
  let navigate = useNavigate();
  return (
    <Menu theme="dark" mode="inline" selectable defaultOpenKeys={["sub2"]} selectedKeys={[selectedBeacon]}>
      <SubMenu key="sub2" icon={<PushpinOutlined />} title="Beacons">
        {lastPositions.map((e: any) => {
          return (
            <Menu.Item
              key={e.beaconId}
              danger={e.status}
            // onClick={()=>{
            //    navigate(`../beacon/${e.beaconId}`);
            // }}
            >
              <div style={{ display: "flex" }}>
                <span style={{ fontSize: 10, marginRight: 10 }}>
                  #{e.beaconId}
                </span>
                <span>{e.name}</span>
                <div style={{ flexGrow: 1 }}></div>
                <span style={{ fontSize: 10 }}>
                  {timeago(new Date(e.dateTime * 1000))}
                </span>
              </div>
              <Link to={`../beacon/${e.beaconId}`}>
              </Link>
            </Menu.Item>
          );
        })}
      </SubMenu>
    </Menu>
  );
};

export const BeaconsMenuLoading = () => {
  return (
    <>
      <Menu theme="dark" mode="inline" defaultOpenKeys={["sub2"]}>
        <SubMenu
          key="sub2"
          icon={<PushpinOutlined />}
          title="Beacons"
        ></SubMenu>
      </Menu>
      <Card
        bordered={false}
        bodyStyle={{ paddingBottom: 0, paddingTop: 0 }}
        style={{ background: "#001529", color: "#fff" }}
        loading
      ></Card>
      <Card
        bordered={false}
        bodyStyle={{ paddingBottom: 15, paddingTop: 0 }}
        style={{ background: "#001529", color: "#fff" }}
        loading
      ></Card>
    </>
  );
};
