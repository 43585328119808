import { Menu } from "antd";
import React from "react";
import {
	ReloadOutlined
} from "@ant-design/icons";

export const UpdateButton = ({ onClick }: { onClick: any }) => {
  return (
    <Menu
      mode="inline"
      theme="dark"
    >
      <Menu.Item
        key="1"
        style={{ backgroundColor: "#000C17" }}
        icon={<ReloadOutlined />}
        onClick={onClick}
      >
        Actualizar
      </Menu.Item>
    </Menu>
  );
};
