import Sider from "antd/lib/layout/Sider";
import React from "react";
import { Link } from "react-router-dom";
import { BeaconsMenu, BeaconsMenuLoading } from "./BeaconsMenu";
import { SideBarLogo } from "./SideBarLogo";
import { StatsFooter, StatsFooterLoading } from "./StatsFooter";
import { UpdateButton } from "./UpdateButton";

export const SideBar = ({
  loaded,
  data,
  onUpdate,
  selectedBeacon,
}: {
  loaded: boolean;
  data: any;
  onUpdate: any;
  selectedBeacon?: string;
}) => {
  return (
    <Sider
      style={{
        overflow: "auto",
        height: "100vh",
        position: "fixed",
        left: 0,
        top: 0,
        bottom: 0,
      }}
      width={300}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        {/* <div onClick={onClickBack} style={{cursor: "pointer"}}> */}
        <Link to="/">
          <SideBarLogo></SideBarLogo>
        </Link>
        {/* </div> */}

        {loaded ? (
          <BeaconsMenu
            lastPositions={data.lastPositions}
            onBeaconClick={() => { }}
            selectedBeacon={selectedBeacon || "0"}
          ></BeaconsMenu>
        ) : (
          <BeaconsMenuLoading></BeaconsMenuLoading>
        )}
        <div
          style={{
            width: "100%",
            marginTop: "auto",
            backgroundColor: "#001529",
          }}
        >
          {loaded ? (
            <StatsFooter
              totalBeacons={data.totalBeacon}
              totalHotSpots={data.totalHotSpot}
              totalMessage={data.totalMessage}
            ></StatsFooter>
          ) : (
            <StatsFooterLoading></StatsFooterLoading>
          )}
          <UpdateButton onClick={onUpdate}></UpdateButton>
        </div>
      </div>
    </Sider>
  );
};
