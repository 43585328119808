import React from "react";
import { Badge, Card, Descriptions, Divider, Menu, PageHeader, Table, Tooltip, Typography } from "antd";
import Sider from "antd/lib/layout/Sider";
import {
  SettingOutlined,
  EditOutlined,
  EllipsisOutlined,
} from "@ant-design/icons";
import SubMenu from "antd/lib/menu/SubMenu";
import { useNavigate } from "react-router";

// @ts-ignore
const { format, register } = require("timeago.js");
register(
  "es_ES",
  (number: any, index: any, total_sec: any) =>
    [
      ["justo ahora", "ahora mismo"],
      ["%s segundos", "en %s segundos"],
      ["1m", "en 1 minuto"],
      ["%sm", "en %s minutos"],
      ["1h", "en 1 hora"],
      ["%sh", "in %s horas"],
      ["1d", "en 1 dia"],
      ["%sd", "en %s dias"],
      ["1 semana", "en 1 semana"],
      ["%s semanas", "en %s semanas"],
      ["1 mes", "en 1 mes"],
      ["%s meses", "en %s meses"],
      ["1 año", "en 1 año"],
      ["%s años", "en %s años"],
    ][index]
);
const timeago = (timestamp: any) => format(timestamp, "es_ES");


// @ts-ignore
const { format: format2, register: register2 } = require("timeago.js");
register2(
  "es_ES",
  (number: any, index: any, total_sec: any) =>
    [
      ["justo ahora", "ahora mismo"],
      ["hace %s segundos", "en %s segundos"],
      ["hace 1 minuto", "en 1 minuto"],
      ["hace %s minutos", "en %s minutos"],
      ["hace 1 hora", "en 1 hora"],
      ["hace %s horas", "in %s horas"],
      ["hace 1 dia", "en 1 dia"],
      ["hace %s dias", "en %s dias"],
      ["hace 1 semana", "en 1 semana"],
      ["hace %s semanas", "en %s semanas"],
      ["1 mes", "en 1 mes"],
      ["hace %s meses", "en %s meses"],
      ["hace 1 año", "en 1 año"],
      ["hace %s años", "en %s años"],
    ][index]
);
const timeago2 = (timestamp: any) => format2(timestamp, "es_ES");



function randomIntFromInterval(min: number, max: number) {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export const DetailSideBar = ({
  data,
}: {
  data: any;
}) => {
  let navigate = useNavigate();
  return (
    <Sider
      style={{
        overflow: "auto",
        height: "100vh",
        position: "fixed",
        left: 0,
        top: 0,
        bottom: 0,
        marginLeft: 300,
      }}
      width={500}
      theme="light"
    >
      <PageHeader
        className="site-page-header"
        onBack={() => {
          navigate(`../../`);
        }}
        subTitle={`#${data.lastPosition.beaconId}`}
        title={`${data.lastPosition.name}`}
      />
      <div style={{
        width: "100%",
        height: "calc(100% - 72px)",
        marginTop: "auto",
        overflowY: "scroll",
        overflowX: "hidden"
      }}>
        <Card
          style={{ width: "100%", border: 'none' }}
          cover={
            <img
              alt="example"
              style={{
                // filter: 'hue-rotate(180deg) saturate(200%)'
              }}
              //src="https://media.discordapp.net/attachments/837563151629156382/947317665008087100/unknown.png?width=960&height=625"
              src={`https://maps.googleapis.com/maps/api/staticmap?center=${data.lastPosition.latitude},${data.lastPosition.longitude}&zoom=18&size=600x300&maptype=hybrid&key=AIzaSyB2ILWp19UR7M1Wn1opFIIZgw1uzF4dUT0`}

            />
          }
        // actions={[
        //   <SettingOutlined key="setting" />,
        //   <EditOutlined key="edit" />,
        //   <EllipsisOutlined key="ellipsis" />,
        // ]}
        >
          <Descriptions
            //   title={`#${data.lastPosition.beaconId} ${data.lastPosition.name}`}
            bordered
            style={{ marginTop: 10 }}
          >
            <Descriptions.Item label="Último visto" span={3}>
              {timeago2(new Date(data.lastPosition.dateTime * 1000))}
            </Descriptions.Item>
            <Descriptions.Item label="Ubicación" span={3}>
              {data.lastPosition.address}
            </Descriptions.Item>
            <Descriptions.Item label="Status" span={3}>
              {!data.lastPosition.status && (
                <Badge status="success" text="Normal" />
              )}
              {data.lastPosition.status && (
                <Badge status="error" text="Robado" />
              )}
            </Descriptions.Item>
            <Descriptions.Item label="Mensajes 24h" span={3}>
              {data.totalMessageOn24h}
            </Descriptions.Item>
            <Descriptions.Item label="Reporta cada" span={3}>
              900ms
            </Descriptions.Item>
          </Descriptions>
        </Card>

        <Card style={{ border: "none" }}>
          <Table size={"small"} dataSource={data.positions} columns={[
            {
              title: 'Id',
              dataIndex: 'positionId',
              key: 'id',
              sorter: (a, b) => a.positionId - b.positionId,
            },
            {
              title: 'Hace',
              dataIndex: 'age',
              key: 'hace',
              render: (a, e) => {
                return <Tooltip title={`${new Date(e.dateTime * 1000).toLocaleString()}`}>{timeago2(new Date(e.dateTime * 1000))}</Tooltip>
              },
              sorter: (a: any, b: any) => new Date(a.dateTime * 1000).valueOf() - new Date(b.dateTime * 1000).valueOf(),
            },
            {
              title: 'Lat',
              dataIndex: 'latitude',
              key: 'lat',
              render: (a, e) => {
                return <Tooltip title={`${e.latitude}`}>

                  {`${e.latitude.toFixed(3)}`}
                </Tooltip>
              },
              sorter: (a, b) => a.latitude - b.latitude,
              onFilter: (value: any, record) => {
                return record.latitude.toFixed(3).indexOf(value) === 0
              },
              filters: (() => {
                const dict: any = {}
                data.positions.forEach((e: any) => {
                  if (!dict[e.latitude.toFixed(3)]) {
                    dict[e.latitude.toFixed(3)] = 1
                  }
                })
                const final = []

                for (const [key, value] of Object.entries(dict)) {
                  final.push({
                    text: key,
                    value: key,
                  })
                }
                return final
              })()
            },
            {
              title: 'Lng',
              dataIndex: 'longitude',
              key: 'lng',
              render: (a, e) => {
                return <Tooltip title={`${e.longitude}`}>
                  {`${e.longitude.toFixed(3)}`}
                </Tooltip>
              },
              sorter: (a, b) => a.longitude - b.longitude,
              onFilter: (value: any, record) => {
                return record.longitude.toFixed(3).indexOf(value) === 0
              },
              filters: (() => {
                const dict: any = {}
                data.positions.forEach((e: any) => {
                  if (!dict[e.longitude.toFixed(3)]) {
                    dict[e.longitude.toFixed(3)] = 1
                  }
                })
                const final = []

                for (const [key, value] of Object.entries(dict)) {
                  final.push({
                    text: key,
                    value: key,
                  })
                }
                return final
              })()
            },
          ]} />
        </Card>
      </div>

    </Sider>
  );
};

export const DetailSideBarLoading = ({ }: {}) => {
  return (
    <Sider
      style={{
        overflow: "auto",
        height: "100vh",
        position: "fixed",
        left: 0,
        top: 0,
        bottom: 0,
        marginLeft: 300,
      }}
      width={500}
      theme="light"
    >
      <Card
        style={{ width: "100%" }}
        cover={
          <img
            alt="example"
            src="https://www.luftechnik.com/wp-content/uploads/2021/02/placeholder.png"
          />
        }
      >
        <>
          <Card
            bordered={false}
            bodyStyle={{ paddingBottom: 0, paddingTop: 0 }}
            loading
          ></Card>
          <Card
            bordered={false}
            bodyStyle={{ paddingBottom: 0, paddingTop: 0 }}
            loading
          ></Card>
          <Card
            bordered={false}
            bodyStyle={{ paddingBottom: 0, paddingTop: 0 }}
            loading
          ></Card>
          <Card
            bordered={false}
            bodyStyle={{ paddingBottom: 15, paddingTop: 0 }}
            loading
          ></Card>
          <Divider></Divider>
          <Card
            bordered={false}
            bodyStyle={{ paddingBottom: 0, paddingTop: 0 }}
            loading
          ></Card>
          <Card
            bordered={false}
            bodyStyle={{ paddingBottom: 15, paddingTop: 0 }}
            loading
          ></Card>
        </>
      </Card>
    </Sider>
  );
};
